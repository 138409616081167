import LayoutUtils from '../../utils/layout_utils';

class Filters {
  init(dashboard) {
    document.querySelectorAll('.js-filter-by-people-radio, .js-filter-by-location-radio').forEach(el => {
      el.addEventListener('change', () => {
        LayoutUtils.toggle(document.querySelectorAll('.js-search-by-filter'));
      });
    });

    if (dashboard.dataset.people && dashboard.dataset.people != '') {
      document.querySelector('.js-filter-by-people-radio')?.click();
    } else {
      document.querySelector('.js-filter-by-location-radio')?.click();
    }
  }
}

export default Filters;
